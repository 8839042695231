import React from 'react';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';

const { hostname } = window.location;
let environment = 'Unknown';
if (hostname === 'zapmanagement.io') {
  environment = 'production';
} else if (hostname === 'dev.zapmanagement.io') {
  environment = 'staging';
} else if (hostname === 'alt.zapmanagement.io') {
  environment = 'alt';
} else if (hostname === 'localhost' || hostname === '127.0.0.1') {
  environment = 'development';
}

Sentry.init({
  dsn: 'https://ad9b9d3a8e0445fd85b7b01be4cae194@o1260544.ingest.sentry.io/6437011',
  integrations: [new BrowserTracing()],
  environment,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0,
});

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);
root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
