import React, { useState } from 'react';
import * as LottiePlayer from 'react-lottie-player/dist/LottiePlayerLight';

export default function Lottie(props) {
  const [isPlay, setIsPlay] = useState(props.play ?? true);

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        cursor: 'pointer',
      }}
      onClick={() => setIsPlay((prev) => !prev)}
    >
      <LottiePlayer play={isPlay} {...props} />
    </div>
  );
}
