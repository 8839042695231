import React from 'react';
import { Link } from 'react-router-dom';
import { Typography } from 'antd';

import notFound from '../../assets/lotties/not-found.json';
import Lottie from 'components/shared-components/Lottie';

export default function NotFound() {
  return (
    <div
      style={{
        display: 'flex',
        gap: 16,
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Lottie
        animationData={notFound}
        style={{
          height: '500px',
          width: '500px',
        }}
      />

      <Typography.Title>
        Go to <Link to="/">Home</Link>
      </Typography.Title>
    </div>
  );
}
