import React from 'react';
import type {
  CellStyleFunc,
  ColDef,
  ICellEditorParams,
  ICellRendererParams,
  ValueFormatterParams,
} from 'ag-grid-community';
import { message, Select, Tag } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import { useQueryClient } from '@tanstack/react-query';

import { getLocalDateAndTime } from 'utils/date';
import Keyword from 'models/keyword';
import { useKeywordRelevance } from './keyword-queries';
import { useRequest } from 'hooks/request';

const redCellIfNotUpdatedRecently: CellStyleFunc<Keyword> = (params) => {
  const { data, column } = params;

  const colId = column.getColId();
  let updatedAt = data?.updatedAt;
  if (colId === 'lastRank') {
    updatedAt = data?.rankLastUpdated;
  } else if (colId === 'lastSearchVolume') {
    updatedAt = data?.searchVolumeLastUpdated;
  }

  if (updatedAt) {
    const now = new Date();
    const updated = new Date(updatedAt);
    const msInDay = 24 * 60 * 60 * 1000;
    const msSinceLastUpdate = now.getTime() - updated.getTime();
    const isNotUpdatedInLastDay = msSinceLastUpdate > msInDay;
    if (isNotUpdatedInLastDay) {
      return { color: '#ff4d4f' };
    }
  }
  return null;
};

const relevanceCellRenderer = (
  params: ICellRendererParams & { isFilterRenderer: boolean }
) => {
  const { value, data } = params;
  if (!value) {
    return '';
  }

  if (typeof value === 'string') {
    return value;
  }

  const { color, name } = value;
  const { isRelevanceAssumed, isRelevancePermanent } = data;

  return (
    <Tag icon={isRelevancePermanent ? <LockOutlined /> : null} color={color}>
      {name} {isRelevanceAssumed ? '(ASSUMED)' : ''}
    </Tag>
  );
};

const relevanceCellEditor = (params: ICellEditorParams) => {
  const [keywordsApi] = useRequest<any>('/api/keywords');

  const { value, data } = params;
  const { data: keywordRelevances = [] } = useKeywordRelevance();

  const handleChange = async (value: any) => {
    console.log('value:', value);
    const newValue = keywordRelevances.find((rel) => rel._id === value);
    if (newValue) {
      // params.data.relevance = newValue;
      // params.data.isRelevanceAssumed = false;
      params.api.flashCells({
        rowNodes: [params.node],
        columns: [params.column.getColId()],
      });

      try {
        const response = await keywordsApi({
          url: `/${data._id}`,
          method: 'PUT',
          data: { ...data, relevance: newValue },
        });
        console.log('response:', response);
        params.node.setData(response);
      } catch (error) {
        console.log(error);
        message.error('Error updating relevance');
      }
    }
    params.stopEditing();
  };

  return (
    <Select
      optionLabelProp="label"
      style={{ width: 200 }}
      tagRender={({ label }: any) => <>{label}</>}
      defaultValue={value._id}
      defaultOpen={true}
      options={[
        ...(keywordRelevances || []).map((relevance) => {
          return {
            value: relevance._id,
            label: (
              <Tag className="m-1" color={relevance.color}>
                {relevance.name}
              </Tag>
            ),
          };
        }),
      ]}
      onChange={(value) => handleChange(value)}
    />
  );
};

const numberFilterParams = {
  filterOptions: ['inRange', 'equals', 'lessThanOrEqual', 'greaterThanOrEqual'],
  suppressAndOrCondition: true,
};

export const getColumnDefs: () => ColDef<Keyword>[] = () => {
  const queryClient = useQueryClient();

  const colDefs: ColDef<Keyword>[] = [
    { field: '', headerName: 'Alert Status' },
    {
      field: 'keyword',
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      filterParams: {
        filterOptions: ['contains'],
        suppressAndOrCondition: true,
      },
      sortable: true,
      cellRenderer: (params: ICellRendererParams) => {
        const phrase = params.value;
        if (!phrase) {
          return '';
        }

        const phraseForUrl = phrase.replace(/\s/g, '+');
        const amazonSearchUrl = `https://www.amazon.com/s?k=${phraseForUrl}`;
        return (
          <div>
            <a href={amazonSearchUrl} target="_blank" rel="noreferrer">
              {phrase}
            </a>
          </div>
        );
      },
    },
    {
      field: 'lastRank',
      headerName: 'Position',
      sortable: true,
      cellStyle: redCellIfNotUpdatedRecently,
      filter: 'agNumberColumnFilter',
      floatingFilter: true,
      filterParams: numberFilterParams,
    },
    {
      field: '',
      headerName: 'Campaigns',
      valueGetter: (params) => params.data?.amazonKeywordIds?.length,
      cellRenderer: 'agGroupCellRenderer',
    },
    {
      field: 'relevance',
      headerName: 'Relevancy',
      sortable: true,
      filter: 'agSetColumnFilter',
      floatingFilter: true,
      filterParams: {
        refreshValuesOnOpen: true,
        cellRenderer: relevanceCellRenderer,
        values: async (params: any) => {
          const data: any = await queryClient.getQueryData([
            'keyword-relevance',
          ]);
          const values = data?.map((relevance: any) => relevance.name);
          return params.success(values);
        },
      },
      cellRenderer: relevanceCellRenderer,
      editable: (params) => !params.data?.isRelevancePermanent,
      cellEditor: relevanceCellEditor,
    },
    {
      field: 'lastSearchVolume',
      headerName: 'Search Volume',
      filter: 'agNumberColumnFilter',
      floatingFilter: true,
      filterParams: numberFilterParams,
      sortable: true,
      valueFormatter: ({ value }) => {
        const searchVolume = +value;
        if (isNaN(searchVolume)) {
          return value;
        }
        return searchVolume.toLocaleString();
      },
      cellStyle: redCellIfNotUpdatedRecently,
    },
    { field: '', headerName: 'Item' },
    { field: '', headerName: 'Assignee' },
    {
      field: 'updatedAt',
      headerName: 'Last Update',
      sortable: true,
      valueFormatter: (params: ValueFormatterParams) => {
        const { value } = params;
        if (!value) {
          return '';
        }

        const formattedDate = getLocalDateAndTime(value);
        return `${formattedDate}`;
      },
    },
  ];

  return colDefs;
};
