import React, { useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import AppContext from 'context/AppContext';

const PrivateRoute = ({ children }: any) => {
  const { jwt, setJwt } = useContext(AppContext);

  const checkExpire = () => {
    if (jwt !== localStorage.getItem('x-auth')) {
      setJwt(localStorage.getItem('x-auth'));
    }
  };
  const location = useLocation();
  checkExpire();

  if (!jwt) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return children;
};

export default PrivateRoute;
