import AppContext from 'context/AppContext';
import React, { useContext } from 'react';
import NotFound from 'views/app-views/not-found';

const AdminRoute = ({ children }: any) => {
  const { user } = useContext(AppContext);

  const role = user?.userRole || '';
  const isAdminRouteAllowed = 'admin|developer'.includes(role.toLowerCase());

  if (isAdminRouteAllowed) {
    return children;
  }

  return <NotFound />;
};

export default AdminRoute;
