// Source: https://stackoverflow.com/a/1353711/5090056
export const isValidDate = (date: number | Date): boolean => {
  return date instanceof Date && !isNaN(new Date(date).getTime());
};

export const getShortMonthName = (date: Date) => {
  const convertedDate = new Date(date);
  if (isValidDate(convertedDate)) {
    return convertedDate.toLocaleDateString('default', { month: 'short' });
  } else {
    return date;
  }
};

export const getLongMonthName = (date: Date) => {
  const convertedDate = new Date(date);
  if (isValidDate(convertedDate)) {
    return convertedDate.toLocaleDateString('default', { month: 'long' });
  } else {
    return date;
  }
};

export const getMonthNameAndYear = (date: Date) => {
  // First convert to date
  const convertedDate = new Date(date);
  if (isValidDate(convertedDate)) {
    return convertedDate.toLocaleDateString('default', {
      month: 'long',
      year: 'numeric',
    });
  } else {
    return date;
  }
};

export const getLocalDateAndTime = (date: Date) => {
  // First convert to date
  const convertedDate = new Date(date);
  if (isValidDate(convertedDate)) {
    return convertedDate.toLocaleString();
  } else {
    return date;
  }
};
