import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';

import { useEstimatedKeywordCounts } from './keyword-queries';

export default function CustomStatusBar() {
  const { data: keywordCounts, isLoading: isKeywordCountsLoading } =
    useEstimatedKeywordCounts();

  return (
    <div className="m-2">
      {isKeywordCountsLoading ? (
        <LoadingOutlined style={{ fontSize: 16 }} spin />
      ) : (
        <div>
          <strong>
            {keywordCounts?.total
              ? Number(keywordCounts.total).toLocaleString()
              : '--'}
          </strong>{' '}
          Keywords
        </div>
      )}
    </div>
  );
}
