export const numberFormatter = new Intl.NumberFormat();

export const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export const percentFormatter = new Intl.NumberFormat('en-US', {
  style: 'percent',
});

export const numberParserRegEx = /\$\s?|(,*)/g;

export const currencyFormatterAndParser = {
  formatter: (value) => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
  parser: (value) => value.replace(/\$\s?|(,*)/g, ''),
};

export const currencyRegEx = /\B(?=(\d{3})+(?!\d))/g;
export const numberToCurrency = (value) =>
  `$${value}`.replace(currencyRegEx, ',');
