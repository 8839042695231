import React from 'react';

const currentYear = new Date().getFullYear();

export default function Footer() {
  return (
    <footer className="footer">
      Copyright © {currentYear} &nbsp; <strong>Zap Imports Inc.</strong> &nbsp;
      All Rights Reserved.
    </footer>
  );
}
