import React from 'react';
import { Menu, Dropdown, Avatar } from 'antd';
import { connect } from 'react-redux';
import { LogoutOutlined } from '@ant-design/icons';
import Icon from 'components/util-components/Icon';
import { signOut } from 'redux/actions/Auth';
// import AppContext from 'context/AppContext';

const menuItem = [
  // {
  //   title: 'Edit Profile',
  //   icon: EditOutlined,
  //   path: '/app/pages/setting/edit-profile',
  // },
  // {
  //   title: 'Account Setting',
  //   icon: SettingOutlined,
  //   path: '/',
  // },
  // {
  //   title: 'User List',
  //   icon: UsergroupAddOutlined,
  //   path: '/users',
  // },
  // {
  //   title: 'Billing',
  //   icon: ShopOutlined,
  //   path: '/',
  // },
  // {
  //   title: 'Help Center',
  //   icon: QuestionCircleOutlined,
  //   path: '/',
  // },
];

export const NavProfile = ({ signOut }) => {
  // const { user } = useContext(AppContext);
  const profileImg = '/img/avatars/thumb-1.png';

  // const profileMenu = (
  //   <div className="nav-profile nav-dropdown">
  //     <div className="nav-profile-header">
  //       <div className="d-flex">
  //         <Avatar size={45} src={profileImg} />
  //         <div className="pl-3">
  //           <h4 className="mb-0">
  //             {user?.fname} {user?.lname}
  //           </h4>
  //           <span className="text-muted">{user?.userRole}</span>
  //         </div>
  //       </div>
  //     </div>
  //     <div className="nav-profile-body">
  //       <Menu
  //         items={[
  //           ...menuItem.map((el, i) => ({
  //             key: i,
  //             label: (
  //               <a href={el.path}>
  //                 <Icon className="mr-3" type={el.icon} />
  //                 <span className="font-weight-normal">{el.title}</span>
  //               </a>
  //             ),
  //           })),
  //           {
  //             key: menuItem.length + 1,
  //             label: (
  //               <div>
  //                 <LogoutOutlined className="mr-3" />
  //                 Sign Out
  //               </div>
  //             ),
  //           },
  //         ]}
  //       />
  //     </div>
  //   </div>
  // );

  return (
    <Dropdown
      placement="bottomRight"
      menu={{
        onClick: ({ key }) => {
          if (key === 'sign-out') {
            signOut();
          }
        },

        items: [
          ...menuItem.map((el) => ({
            key: el.key,
            label: (
              <a href={el.path}>
                <Icon className="mr-3" type={el.icon} />
                <span className="font-weight-normal">{el.title}</span>
              </a>
            ),
          })),
          {
            key: 'sign-out',
            label: (
              <div>
                <LogoutOutlined className="mr-3" />
                Sign Out
              </div>
            ),
          },
        ],
      }}
      trigger={['click']}
    >
      <Menu
        className="d-flex align-item-center"
        mode="horizontal"
        items={[
          {
            key: 'profile-avatar',
            label: <Avatar src={profileImg} />,
          },
        ]}
      />
    </Dropdown>
  );
};

export default connect(null, { signOut })(NavProfile);
