import React, { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LogRocket from 'logrocket';
import * as Sentry from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { IntlProvider } from 'react-intl';
import { ConfigProvider } from 'antd';

import store from './redux/store';
import AppContext from 'context/AppContext';
import Login from './views/auth-views/authentication/login';
import './index.css';
import PrivateRoute from 'components/PrivateRoute';
import AppLocale from 'lang';
import AppLayout from 'layouts/app-layout';

const isProduction = window.location.hostname !== 'localhost';

if (isProduction) {
  LogRocket.init('iewfci/zap-management');

  LogRocket.getSessionURL((sessionURL) => {
    Sentry.configureScope((scope) => {
      scope.setExtra('sessionURL', sessionURL);
    });
  });
}

const queryClient = new QueryClient();
const serverURL = import.meta.env.VITE_API_URL;

function App() {
  let storageUser;
  try {
    storageUser = JSON.parse(localStorage.getItem('x-user') || '');

    if (isProduction && storageUser._id) {
      LogRocket.identify(storageUser._id, {
        name: `${storageUser.fname || ''} ${storageUser.lname || ''}`,
        email: storageUser.email,
      });
    }
  } catch (e) {
    console.log('Error in getting user from local storage');
  }
  const [jwt, setJwt] = useState(localStorage.getItem('x-auth'));
  const [user, setUser] = useState(storageUser);
  const [apiStatus, setApiStatus] = useState();

  if (!serverURL) {
    throw new Error(`API_URL is ${serverURL}`);
  }

  const contextValues = { jwt, setJwt, user, setUser, serverURL, setApiStatus };
  const currentAppLocale = AppLocale['en'];

  useEffect(() => {
    if (apiStatus === 401) {
      window.localStorage.clear();
      const baseUrl = window.location.origin;
      window.location.assign(`${baseUrl}/login`);
    }
  }, [apiStatus]);

  return (
    <div className="App">
      <AppContext.Provider value={contextValues}>
        <Provider store={store}>
          <QueryClientProvider client={queryClient}>
            <IntlProvider
              locale={currentAppLocale.locale}
              messages={currentAppLocale.messages}
            >
              <ConfigProvider locale={currentAppLocale.antd}>
                <Router>
                  <Routes>
                    <Route path="login" element={<Login />} />
                    <Route
                      path="/*"
                      element={
                        <PrivateRoute>
                          <AppLayout />
                        </PrivateRoute>
                      }
                    />
                  </Routes>
                </Router>
              </ConfigProvider>
            </IntlProvider>

            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </Provider>
      </AppContext.Provider>
    </div>
  );
}

export default App;
