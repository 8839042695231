import { useQuery } from '@tanstack/react-query';

import { useRequest } from 'hooks/request';
import { KeywordRelevance } from 'models/keyword';

export const useEstimatedKeywordCounts = () => {
  const [keywordsCountApi] = useRequest<{
    total: number;
  }>('/api/keywords/count-all');

  return useQuery(['keywords-count-all'], () => keywordsCountApi(), {
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });
};

export const useKeywordCounts = () => {
  const [keywordsCountApi] = useRequest<{
    total: number;
    filteredTotal: number;
    deletedTotal: number;
  }>('/api/keywords/count');

  return useQuery(['keywords-count'], () => keywordsCountApi(), {
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });
};

export const useKeywordRelevance = () => {
  const [relevanceApi] = useRequest<KeywordRelevance[]>(
    '/api/keywords/relevance'
  );

  return useQuery(['keyword-relevance'], () => relevanceApi(), {
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    cacheTime: 1000,
  });
};
