import { useContext } from 'react';
import AppContext from '../context/AppContext';
import axios, { AxiosRequestConfig } from 'axios';

export const useRequest = <T>(url: string) => {
  const { serverURL, jwt, setApiStatus } = useContext(AppContext);

  let baseURL = serverURL + url;
  if (url.startsWith('http')) {
    baseURL = url;
  }

  const instance = axios.create({
    baseURL,
    headers: { Authorization: `Bearer ${jwt}` },
  });

  const request = async (options: AxiosRequestConfig = { method: 'GET' }) => {
    return await new Promise<T>((resolve, reject) => {
      instance(options)
        .then((res) => {
          if (res.status === 401) {
            setApiStatus(res.status);
          }
          resolve(res.data);
        })
        .catch((error) => {
          console.log('error in hook: ', error);
          setApiStatus(error?.response?.status ? error.response.status : error);

          reject(
            error.response
              ? error.response.data
                ? error.response.data
                : error.response.status
              : error
          );
        });
    });
  };

  return [request];
};
