import React, { useCallback, useRef, useState } from 'react';
import {
  Button,
  Dropdown,
  Input,
  message,
  Select,
  Space,
  Switch,
  Typography,
} from 'antd';
import type { SelectProps, MenuProps } from 'antd';
import {
  DownOutlined,
  ReloadOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { useQueryClient } from '@tanstack/react-query';

import { getLocalDateAndTime } from '../../../../utils/date';
import { currencyFormatter } from '../../../../utils/NumberFormatter';

/**************
 *    AG Grid
 */
import { AgGridReact } from 'ag-grid-react';
import type {
  GetDetailRowDataParams,
  GetRowIdParams,
  GridReadyEvent,
  ICellRendererParams,
  IDetailCellRendererParams,
} from 'ag-grid-community';
import { LicenseManager } from 'ag-grid-enterprise';
import type {
  GridOptions,
  IServerSideDatasource,
  IServerSideGetRowsParams,
} from 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

LicenseManager.setLicenseKey(
  'For_Trialing_ag-Grid_Only-Not_For_Real_Development_Or_Production_Projects-Valid_Until-7_December_2022_[v2]_MTY3MDM3MTIwMDAwMA==7af1499e9b7e27a3dcb51e1a5b382d2a'
);

import { useRequest } from 'hooks/request';
import CustomStatusBar from './status-bar';
import { getColumnDefs } from './column-defs';
import { useKeywordRelevance } from './keyword-queries';
import Keyword, { AmazonKeyword, KeywordRelevance } from 'models/keyword';

const { Title } = Typography;

const statusOptions: SelectProps['options'] = [
  {
    label: 'High Alert',
    value: 'high',
  },
  {
    label: 'Medium Alert',
    value: 'medium',
  },
  {
    label: 'Low Alert',
    value: 'low',
  },
  {
    label: 'Snoozed',
    value: 'snoozed',
  },
  {
    label: 'Optimized',
    value: 'optimized',
  },
  {
    label: 'Archived',
    value: 'archived',
  },
];

const filterOptions: MenuProps['items'] = [
  { key: 'position', label: 'Position' },
  { key: 'campaignType', label: 'Campaign Type' },
  { key: 'relevancy', label: 'Relevancy' },
  { key: 'searchVolume', label: 'Search Volume' },
  { key: 'indexed', label: 'Indexed' },
  { key: 'assignee', label: 'Assignee' },
];

export default function KeywordManagement() {
  const [keywordsApi] = useRequest<any>('/api/keywords');
  const [advertisingApi] = useRequest('/api/advertising');
  const queryClient = useQueryClient();

  // Load to use later from cache
  useKeywordRelevance();

  const [isKeywordsLoading, setIsKeywordsLoading] = useState(false);

  document.title = `Keyword Management | Zap Management`;

  const handleAddFilter: MenuProps['onClick'] = ({ key }) => {
    console.log(key);
  };

  /**
   * Grid properties
   */

  const gridRef = useRef<AgGridReact>(null);

  const createDatasource: () => IServerSideDatasource = () => {
    return {
      getRows: async (params: IServerSideGetRowsParams) => {
        const { endRow = 100, filterModel, sortModel } = params.request;

        const pageSize = 100;
        const current = endRow / pageSize;

        const searchParams = new URLSearchParams({
          pageSize: `${pageSize}`,
          current: `${current}`,
        });

        if (sortModel?.length) {
          for (const sorter of sortModel) {
            searchParams.append('sortField', sorter.colId);
            searchParams.append('sortOrder', sorter.sort);
          }
        }

        const phraseFilter = filterModel?.keyword?.filter;
        if (phraseFilter) {
          searchParams.set('phraseFilter', phraseFilter);
        }

        const rankFilter = filterModel?.lastRank;
        if (rankFilter) {
          const filterType = rankFilter.type;
          if (filterType === 'inRange') {
            const rankMin = rankFilter.filter;
            const rankMax = rankFilter.filterTo;
            searchParams.set('rankMin', rankMin);
            searchParams.set('rankMax', rankMax);
          } else if (filterType === 'equals') {
            const rank = rankFilter.filter;
            searchParams.set('rankMin', rank);
            searchParams.set('rankMax', rank);
          } else if (filterType === 'lessThanOrEqual') {
            const rank = rankFilter.filter;
            searchParams.set('rankMax', rank);
          } else if (filterType === 'greaterThanOrEqual') {
            const rank = rankFilter.filter;
            searchParams.set('rankMin', rank);
          }
        }

        const searchVolumeFilter = filterModel?.lastSearchVolume;
        if (searchVolumeFilter) {
          const searchVolumeMin = searchVolumeFilter.filter;
          const searchVolumeMax = searchVolumeFilter.filterTo;
          searchParams.set('searchVolumeMin', searchVolumeMin);
          searchParams.set('searchVolumeMax', searchVolumeMax);
        }

        const relevanceFilter = filterModel?.relevance;
        if (relevanceFilter) {
          const relevanceList: KeywordRelevance[] =
            (await queryClient.getQueryData(['keyword-relevance'])) || [];
          if (relevanceList) {
            const values = relevanceFilter.values.map(
              (r: any) => relevanceList.find((rel: any) => rel.name === r)?._id
            );
            searchParams.set('relevanceFilter', values.join(','));
          }
        }

        try {
          setIsKeywordsLoading(true);

          const { keywords, total } = await keywordsApi({
            url: `?${searchParams}`,
          });

          if (keywords) {
            params.success({
              rowData: keywords,
              rowCount: total,
            });
          } else {
            params.fail();
          }
        } catch (error) {
          params.fail();
          console.log(error);
          message.error('Error loading keywords.');
        } finally {
          setIsKeywordsLoading(false);
        }
      },
    };
  };

  const handleGridReady = useCallback((params: GridReadyEvent) => {
    const datasource = createDatasource();
    params.api.setServerSideDatasource(datasource);
  }, []);

  const reloadKeywords = useCallback(() => {
    gridRef.current?.api.refreshServerSide();
  }, []);

  const gridOptions: GridOptions = {
    columnDefs: getColumnDefs(),
    defaultColDef: {
      resizable: true,
      menuTabs: ['generalMenuTab'],
    },
    statusBar: {
      statusPanels: [
        {
          statusPanel: CustomStatusBar,
          align: 'right',
        },
      ],
    },
    sideBar: {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
          toolPanelParams: {
            suppressRowGroups: true,
            suppressValues: true,
            suppressPivotMode: true,
          },
        },
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
      ],
    },

    multiSortKey: 'ctrl',
    rowModelType: 'serverSide',
    serverSideInfiniteScroll: true,
    cacheBlockSize: 100,
    maxBlocksInCache: 10,
    onGridReady: handleGridReady,
    rowSelection: 'multiple',
    enableRangeSelection: true,
    enableCharts: true,
    masterDetail: true,
    isRowMaster: (data: Keyword) => !!data.amazonKeywordIds?.length,
    detailCellRendererParams: () =>
      ({
        detailGridOptions: {
          defaultColDef: {
            resizable: true,
          },
          columnDefs: [
            {
              headerName: 'Active',
              field: 'state',
              cellRenderer: (
                params: ICellRendererParams<AmazonKeyword, string>
              ) => (
                <Switch
                  size="small"
                  defaultChecked={params.value === 'ENABLED'}
                  onChange={(checked, event) => {
                    console.log('cellRenderer params:', params);
                    console.log('checked:', checked);
                    console.log('event:', event);

                    const keyword = params.data;
                    const updatedState = checked ? 'ENABLED' : 'PAUSED';

                    if (keyword) {
                      advertisingApi({
                        url: '/keyword',
                        method: 'POST',
                        data: {
                          keywordId: keyword.keywordId,
                          state: updatedState,
                        },
                      })
                        .then((result) => {
                          console.log(result);
                          message.success('Success!');
                        })
                        .catch((error) => {
                          console.log(error);
                          message.error('Error updating state');
                        });
                    }
                  }}
                />
              ),
            },
            {
              field: 'bid',
              editable: true,
              valueParser: (params) => Number(params.newValue),
              onCellValueChanged: (event) => {
                const { data } = event;

                const { keywordId, bid } = data;

                advertisingApi({
                  url: '/keyword',
                  method: 'POST',
                  data: {
                    keywordId,
                    bid,
                  },
                })
                  .then((result) => {
                    console.log(result);
                    message.success('Success!');
                  })
                  .catch((error) => {
                    console.log(error);
                    message.error('Error updating bid');
                  });
              },
            },
            { field: 'matchType' },
            {
              field: 'impressions',
            },
            {
              field: 'cost',
              valueFormatter: ({ value }) =>
                value ? currencyFormatter.format(value) : value,
            },
            {
              field: 'clicks',
            },
            {
              headerName: 'Orders',
              field: 'purchases1d',
            },
            {
              headerName: 'Serving Status',
              field: 'extendedData.servingStatus',
            },
            {
              headerName: 'Created',
              field: 'extendedData.creationDateTime',
              valueFormatter: ({ value }) => getLocalDateAndTime(value),
            },
            {
              headerName: 'Updated',
              field: 'extendedData.lastUpdateDateTime',
              valueFormatter: ({ value }) => getLocalDateAndTime(value),
            },
            { field: 'campaignId' },
            { field: 'adGroupId' },
            { field: 'keywordId' },
          ],
        },
        getDetailRowData: (params: GetDetailRowDataParams<Keyword>) => {
          const keywordIds = params.data.amazonKeywordIds;

          advertisingApi({
            url: `/amazon-keywords`,
            method: 'POST',
            data: {
              keywordIds,
              extended: true,
            },
          })
            .then((response) => {
              const { keywords } = response as any;
              return params.successCallback(keywords);
            })
            .catch((error) => {
              message.error('Error requesting keywords');
              console.log(error);
              params.successCallback([]);
            });
        },
      } as IDetailCellRendererParams<Keyword, AmazonKeyword>),
    getRowId: (params: GetRowIdParams<Keyword>) => {
      return params.data._id || '';
    },
  };

  return (
    <div>
      <Title level={2}>Keyword Management</Title>

      <div className="d-flex justify-content-between my-3">
        <Space>
          <Input placeholder="Search Keyword..." style={{ width: 150 }} />

          <Input placeholder="Search Item..." style={{ width: 150 }} />

          <Select
            mode="multiple"
            allowClear
            style={{ width: 250 }}
            placeholder="Alert Status"
            options={statusOptions}
            maxTagCount="responsive"
          />

          <Dropdown
            menu={{ onClick: handleAddFilter, items: filterOptions }}
            trigger={['click']}
          >
            <Button shape="round">
              Add Filter <DownOutlined />
            </Button>
          </Dropdown>

          <span>
            Only active campaigns <Switch defaultChecked />
          </span>
        </Space>

        <Space>
          <Button>
            <Space>
              <span>Bulk Actions</span>
              <DownOutlined />
            </Space>
          </Button>

          <Button shape="circle" icon={<SettingOutlined />} />

          <Button
            shape="circle"
            icon={<ReloadOutlined spin={isKeywordsLoading} />}
            onClick={() => reloadKeywords()}
            disabled={isKeywordsLoading}
          />
        </Space>
      </div>

      <div
        className="ag-theme-alpine w-100"
        style={{ height: 'max(500px, calc(100vh - 285px))' }}
      >
        <AgGridReact ref={gridRef} gridOptions={gridOptions} />
      </div>
    </div>
  );
}
