import User from 'models/auth-user';
import React from 'react';

export default React.createContext<{
  serverURL: string | null;
  jwt: string | null;
  setJwt?: any;
  setApiStatus?: any;
  user: User | null;
}>({
  serverURL: '',
  jwt: '',
  setApiStatus: () => null,
  user: null,
});
