import React from 'react';
import LoginForm from '../../components/LoginForm';
import { Card, Row, Col } from 'antd';

const LoginOne = (props) => {
  return (
    <div className="h-100">
      <div className="container d-flex flex-column justify-content-center h-100">
        <Row justify="center">
          <Col xs={20} sm={20} md={20} lg={7} style={{ marginTop: 150 }}>
            <Card>
              <div className="mt-2">
                <div className="text-center">
                  <img
                    className="img-fluid"
                    src="/img/zap-icon-black.png"
                    alt=""
                  />
                  <h2 className="mt-4">Zap Management</h2>
                </div>
                <Row justify="center">
                  <Col xs={24} sm={24} md={20} lg={20}>
                    <LoginForm {...props} otherSignIn={false} />
                  </Col>
                </Row>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default LoginOne;
