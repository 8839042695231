import React from 'react';
import { Link } from 'react-router-dom';
import { Menu, Grid } from 'antd';
import IntlMessage from '../util-components/IntlMessage';
import Icon from '../util-components/Icon';
import navigationConfig from 'configs/NavigationConfig';
import { connect } from 'react-redux';
import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE } from 'constants/ThemeConstant';
import utils from 'utils';
import { onMobileNavToggle } from 'redux/actions/Theme';

const { useBreakpoint } = Grid;

const setLocale = (isLocaleOn, localeKey) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();

const setDefaultOpen = (key) => {
  let keyList = [];
  let keyString = '';
  if (key) {
    const arr = key.split('-');
    for (let index = 0; index < arr.length; index++) {
      const elm = arr[index];
      index === 0 ? (keyString = elm) : (keyString = `${keyString}-${elm}`);
      keyList.push(keyString);
    }
  }
  return keyList;
};

const SideNavContent = (props) => {
  const {
    sideNavTheme,
    routeInfo,
    hideGroupTitle,
    localization,
    onMobileNavToggle,
  } = props;
  const isMobile = !utils.getBreakPoint(useBreakpoint()).includes('lg');
  const closeMobileNav = () => {
    if (isMobile) {
      onMobileNavToggle(false);
    }
  };

  const sideNavMenuItems = navigationConfig.map((menuItem) => {
    if (menuItem.submenu?.length) {
      const menuItemGroup = {
        type: 'group',
        key: menuItem.key,
        label: setLocale(localization, menuItem.title),
        children: menuItem.submenu.map((submenu) => {
          if (submenu.submenu?.length) {
            return {
              label: setLocale(localization, submenu.title),
              icon: submenu.icon ? <Icon type={submenu?.icon} /> : null,
              children: submenu.submenu.map((innerSubmenu) => ({
                key: innerSubmenu.key,
                disabled: innerSubmenu.disabled,
                icon: innerSubmenu.icon ? (
                  <Icon type={innerSubmenu?.icon} />
                ) : null,
                label: (
                  <Link onClick={() => closeMobileNav()} to={innerSubmenu.path}>
                    {setLocale(localization, innerSubmenu.title)}
                  </Link>
                ),
              })),
            };
          } else {
            const submenuItem = {
              key: submenu.key,
              disabled: submenu.disabled,
              icon: submenu.icon ? <Icon type={submenu.icon} /> : null,
              label: (
                <Link onClick={() => closeMobileNav()} to={submenu.path}>
                  {setLocale(localization, submenu.title)}
                </Link>
              ),
            };
            return submenuItem;
          }
        }),
      };
      return menuItemGroup;
    } else {
      return {
        key: menuItem.key,
        disabled: menuItem.disabled,
        icon: menuItem.icon ? <Icon type={menuItem?.icon} /> : null,
        label: menuItem.path ? (
          <Link onClick={() => closeMobileNav()} to={menuItem.path}>
            {setLocale(localization, menuItem?.title)}
          </Link>
        ) : (
          setLocale(localization, menuItem?.title)
        ),
      };
    }
  });

  return (
    <Menu
      theme={sideNavTheme === SIDE_NAV_LIGHT ? 'light' : 'dark'}
      mode="inline"
      style={{ height: '100%', borderRight: 0, overflowX: 'hidden' }}
      defaultSelectedKeys={[routeInfo?.key]}
      defaultOpenKeys={setDefaultOpen(routeInfo?.key)}
      className={hideGroupTitle ? 'hide-group-title' : ''}
      items={sideNavMenuItems}
    />
  );
};

const TopNavContent = (props) => {
  const { topNavColor, localization } = props;

  const topNavItems = navigationConfig.map((menuItem) => {
    if (menuItem.submenu?.length) {
      const firstLevelSubmenu = {
        key: menuItem.key,
        popupClassName: 'top-nav-menu',
        icon: menuItem.icon ? <Icon type={menuItem?.icon} /> : null,
        title: setLocale(localization, menuItem.title),
        children: menuItem.submenu.map((submenu) => {
          // Check for inner submenu
          if (submenu.submenu?.length) {
            const secondLevelSubmenu = {
              key: submenu.key,
              icon: submenu.icon ? <Icon type={submenu.icon} /> : null,
              title: setLocale(localization, submenu.title),
              children: submenu.submenu.map((innerSubmenuItem) => ({
                key: innerSubmenuItem.key,
                disabled: innerSubmenuItem.disabled,
                icon: innerSubmenuItem.icon ? (
                  <Icon type={innerSubmenuItem.icon} />
                ) : null,
                label: <Link to={innerSubmenuItem.path} />,
              })),
            };
            return secondLevelSubmenu;
          } else {
            const firstLevelSubmenuItem = {
              key: submenu.key,
              icon: submenu.icon ? <Icon type={submenu.icon} /> : null,
              disabled: submenu.disabled,
              label: (
                <Link to={submenu.path}>
                  {setLocale(localization, submenu.title)}
                </Link>
              ),
            };
            return firstLevelSubmenuItem;
          }
        }),
      };
      return firstLevelSubmenu;
    } else {
      const topLevelMenuItem = {
        key: menuItem.key,
        disabled: menuItem.disabled,
        icon: menuItem.icon ? <Icon type={menuItem.icon} /> : null,
        label: menuItem.path ? (
          <Link to={menuItem.path}>
            {setLocale(localization, menuItem?.title)}
          </Link>
        ) : (
          setLocale(localization, menuItem?.title)
        ),
      };
      return topLevelMenuItem;
    }
  });

  return (
    <Menu
      mode="horizontal"
      style={{ backgroundColor: topNavColor }}
      items={topNavItems}
    />
  );
};

const MenuContent = (props) => {
  return props.type === NAV_TYPE_SIDE ? (
    <SideNavContent {...props} />
  ) : (
    <TopNavContent {...props} />
  );
};

const mapStateToProps = ({ theme }) => {
  const { sideNavTheme, topNavColor } = theme;
  return { sideNavTheme, topNavColor };
};

export default connect(mapStateToProps, { onMobileNavToggle })(MenuContent);
