import React from 'react';
import Icon from '@ant-design/icons';

const CustomIcon = React.forwardRef((props, ref) => (
  <Icon ref={ref} component={props.svg} className={props.className} />
));

CustomIcon.displayName = 'CustomIcon';

export default CustomIcon;
