import React from 'react';
import { Layout, Grid, Alert, Button } from 'antd';
import { connect } from 'react-redux';
import SideNav from 'components/layout-components/SideNav';
import TopNav from 'components/layout-components/TopNav';
import MobileNav from 'components/layout-components/MobileNav';
import HeaderNav from 'components/layout-components/HeaderNav';
import PageHeader from 'components/layout-components/PageHeader';
import Footer from 'components/layout-components/Footer';
import AppViews from 'views/app-views';
import navigationConfig from 'configs/NavigationConfig';
import { NAV_TYPE_SIDE, NAV_TYPE_TOP } from 'constants/ThemeConstant';
import utils from 'utils';
import { ErrorBoundary } from 'react-error-boundary';
import { useLocation } from 'react-router-dom';

const { Content } = Layout;
const { useBreakpoint } = Grid;

function ErrorFallback({ error, resetErrorBoundary }) {
  return (
    <Alert
      type="error"
      message="Something went wrong:"
      description={
        <>
          <pre className="mb-3">{error.message}</pre>
          <Button danger size="small" onClick={resetErrorBoundary}>
            Refresh
          </Button>
        </>
      }
      closable
      onClose={resetErrorBoundary}
    />
  );
}

export const AppLayout = ({ navType }) => {
  const location = useLocation();
  const currentRouteInfo = utils.getRouteInfo(
    navigationConfig,
    location.pathname
  );
  const screens = utils.getBreakPoint(useBreakpoint());
  const isMobile = !screens.includes('lg');
  const isNavSide = navType === NAV_TYPE_SIDE;
  const isNavTop = navType === NAV_TYPE_TOP;

  return (
    <Layout>
      <HeaderNav isMobile={isMobile} />
      {isNavTop && !isMobile ? <TopNav routeInfo={currentRouteInfo} /> : null}
      <Layout className="app-container">
        {isNavSide && !isMobile ? (
          <SideNav routeInfo={currentRouteInfo} />
        ) : null}
        <Layout className="app-layout">
          <div className={`app-content ${isNavTop ? 'layout-top-nav' : ''}`}>
            <PageHeader
              display={currentRouteInfo?.breadcrumb}
              title={currentRouteInfo?.title}
            />
            <Content>
              <ErrorBoundary
                FallbackComponent={ErrorFallback}
                onReset={() => {
                  // Reset the app state
                  console.log('Reset app');
                }}
              >
                <AppViews />
              </ErrorBoundary>
            </Content>
          </div>
          <Footer />
        </Layout>
      </Layout>
      {isMobile && <MobileNav />}
    </Layout>
  );
};

const mapStateToProps = ({ theme }) => {
  const { navCollapsed, navType, locale } = theme;
  return { navCollapsed, navType, locale };
};

export default connect(mapStateToProps)(React.memo(AppLayout));
